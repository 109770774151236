import {ChakraProvider} from "@chakra-ui3/react";
import {ColorModeProvider, ColorModeProviderProps} from "./color-mode";
import {system} from "./theme";

export function Provider(props: ColorModeProviderProps) {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider {...props} />
    </ChakraProvider>
  );
}
