import ReactDOM from "react-dom/client";

import {analyticsInit} from "./utils/analytics";
import {initRouter} from "./router/init";
import App from "./App";

analyticsInit();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

initRouter().then(() => {
  root.render(<App />);
});
