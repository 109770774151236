import {invalidateQueries, q} from "../../state";
import {
  CreateOperationalContext,
  OperationalContext,
  OperationalContextAsset,
  OperationalContextId,
  ThirdPartyId,
  TprmAssetId,
  UpdateOperationalContext,
} from "../../Types";
import jsonApi from "../jsonApi";

export async function create(operationalContext: CreateOperationalContext) {
  const res = await jsonApi.post<"ok">("/tprm/operational_contexts", operationalContext);
  await invalidateQueries([q.tprm.thirdPartyOperationalContexts.filter(operationalContext.third_party_id)]);
  return res;
}

export async function get(operationalContextId: OperationalContextId) {
  return await jsonApi.get<OperationalContext>(`/tprm/operational_contexts/${operationalContextId}`);
}

export async function update(
  thirdPartyId: ThirdPartyId,
  operationalContextId: OperationalContextId,
  update: UpdateOperationalContext,
) {
  const res = await jsonApi.patch<"ok">(`/tprm/operational_contexts/${operationalContextId}`, update);
  await invalidateQueries([
    q.tprm.thirdPartyOperationalContexts.filter(thirdPartyId),
    q.tprm.operationalContext.filter(operationalContextId),
  ]);
  return res;
}

export async function delete_(thirdPartyId: ThirdPartyId, operationalContextId: OperationalContextId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/operational_contexts/${operationalContextId}`);
  await invalidateQueries([
    q.tprm.thirdPartyOperationalContexts.filter(thirdPartyId),
    q.tprm.operationalContext.filter(operationalContextId),
  ]);
  return res;
}

export async function listAssets(operationalContextId: OperationalContextId) {
  return await jsonApi.get<OperationalContextAsset[]>(`/tprm/operational_contexts/${operationalContextId}/assets`);
}

export async function linkAsset(operationalContextId: OperationalContextId, assetId: TprmAssetId) {
  const res = await jsonApi.put<"ok">(`/tprm/operational_contexts/${operationalContextId}/assets/${assetId}`);
  await invalidateQueries([q.tprm.operationalContextAssets.filter(operationalContextId)]);
  return res;
}

export async function unlinkAsset(operationalContextId: OperationalContextId, assetId: TprmAssetId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/operational_contexts/${operationalContextId}/assets/${assetId}`);
  await invalidateQueries([q.tprm.operationalContextAssets.filter(operationalContextId)]);
  return res;
}
