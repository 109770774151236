import {
  CreateDataRoom,
  CreateThirdParty,
  DataRoomMin,
  OperationalContext,
  OwnerId,
  ThirdParty,
  ThirdPartyId,
  ThirdPartyMin,
  ThirdPartyTagId,
  ThirdPartyTierId,
  TprmExternalQuestionnaireMin,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function list(): Promise<ThirdPartyMin[]> {
  return await jsonApi.get<ThirdPartyMin[]>("/tprm/third_parties");
}

export async function get(thirdPartyId: ThirdPartyId): Promise<ThirdParty> {
  return await jsonApi.get<ThirdParty>(`/tprm/third_parties/${thirdPartyId}`);
}

export async function create(thirdParty: CreateThirdParty): Promise<ThirdParty> {
  const res = await jsonApi.post<ThirdParty>("/tprm/third_parties", thirdParty);
  await invalidateQueries([q.tprm.thirdParties.filter()]);
  return res;
}

export async function delete_(thirdPartyId: ThirdPartyId): Promise<string> {
  const res = await jsonApi.delete_<"ok">(`/tprm/third_parties/${thirdPartyId}`);
  await invalidateQueries([q.tprm.thirdParties.filter()]);
  return res;
}

export async function updateName(thirdPartyId: ThirdPartyId, name: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/name`, name);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function updateUrl(thirdPartyId: ThirdPartyId, url: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/url`, url);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function updateTier(thirdPartyId: ThirdPartyId, tierId: ThirdPartyTierId | null): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/tier_id`, tierId);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function updateExternalId(thirdPartyId: ThirdPartyId, externalId: string): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/external_id`, externalId);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function updateTags(thirdPartyId: ThirdPartyId, tagIds: ThirdPartyTagId[]): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/tag_ids`, tagIds);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function updateOwner(thirdPartyId: ThirdPartyId, ownerId: OwnerId | null): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/owner_id`, ownerId);
  await invalidateQueries([q.tprm.thirdParties.filter(), q.tprm.thirdParty.filter(thirdPartyId)]);
  return res;
}

export async function listDataRooms(thirdPartyId: ThirdPartyId): Promise<DataRoomMin[]> {
  return await jsonApi.get<DataRoomMin[]>(`/tprm/third_parties/${thirdPartyId}/data_rooms`);
}

export async function createDataRoom(thirdPartyId: ThirdPartyId, dataRoom: CreateDataRoom): Promise<string> {
  const res = await jsonApi.post<"ok">(`/tprm/third_parties/${thirdPartyId}/data_rooms`, dataRoom);
  await invalidateQueries([q.tprm.thirdPartyDataRooms.filter(thirdPartyId)]);
  return res;
}

export async function listExternalQuestionnaires(thirdPartyId: ThirdPartyId): Promise<TprmExternalQuestionnaireMin[]> {
  return await jsonApi.get<TprmExternalQuestionnaireMin[]>(
    `/tprm/third_parties/${thirdPartyId}/external_questionnaires`,
  );
}

export async function listOperationalContexts(thirdPartyId: ThirdPartyId): Promise<OperationalContext[]> {
  return await jsonApi.get<OperationalContext[]>(`/tprm/third_parties/${thirdPartyId}/operational_contexts`);
}
