import React from "react";
import * as Sentry from "@sentry/react";
import type {Integration as SentryIntegration} from "@sentry/types";
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router-dom";
import Plausible from "plausible-tracker";

import {Account, RegisteredUser, Environment} from "../Types";
import {getFrontendEnvironment, getPublicCredentials} from "./environment";
import {LogType} from "platformed-browser-api/platformed";
import {extensionClient} from "../Extension/provider";
import {Json} from "platformed-browser-api/protocol";

let plausibleProps: {readonly [propName: string]: string | number | boolean} = {};

function sanitizeLogArg(arg: any): Json {
  try {
    if (arg instanceof Error) {
      return arg.toString();
    }
    return JSON.parse(JSON.stringify(arg));
  } catch {
    return `${arg}`;
  }
}

declare global {
  interface Window {
    SentryClient: ReturnType<typeof Sentry.init>;
  }
}

export function analyticsInit() {
  try {
    const {environment, release} = getFrontendEnvironment();
    const {sentry_dsn} = getPublicCredentials();

    // Forward log messages to parent window in case we are running in the extension
    // sidebar's iframe, which cannot otherwise be debugged in Firefox.
    if (environment === Environment.Development && window.parent !== window) {
      for (const logType of Object.values(LogType) as LogType[]) {
        const original = console[logType];
        console[logType] = (...args) => {
          original(...args);
          extensionClient.request("logMessage", logType, args.map(sanitizeLogArg));
        };
      }
      window.addEventListener("error", e => {
        if (!e.error?._isNominal) {
          extensionClient.request("logMessage", LogType.Error, [sanitizeLogArg(e)]);
        }
      });
      console.log("analyticsInit");
    }

    window.addEventListener("error", e => {
      if (e.error?._isNominal) {
        e.preventDefault();
      }
    });

    const {trackPageview} = Plausible({
      domain: window.location.hostname,
      apiHost: "/relay/plausible",
      trackLocalhost: true,
    });

    // Automatically track plausible pageviews
    const page = () => trackPageview(undefined, {props: {...plausibleProps}});
    // Attach pushState and popState listeners
    const originalPushState = history.pushState;
    if (originalPushState) {
      history.pushState = function (data, title, url) {
        originalPushState.apply(this, [data, title, url]);
        page();
      };
      addEventListener("popstate", page);
    }

    // Trigger first page view
    page();

    if (sentry_dsn) {
      const integrations: SentryIntegration[] = [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.captureConsoleIntegration({levels: ["error"]}),
        Sentry.replayIntegration({
          blockAllMedia: false,
          maskAllInputs: false,
          maskAllText: false,
        }),
      ];
      window.SentryClient = Sentry.init({
        dsn: sentry_dsn,
        environment,
        release,
        integrations,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: ["Non-Error promise rejection captured"],
        beforeSend(event, hint) {
          // Don't report "nominal" exceptions
          const originalException = hint.originalException as {_isNominal?: boolean} | undefined;
          if (originalException?._isNominal) {
            return null;
          }
          // Prevent grouping errors across different environments
          event.fingerprint = ["{{ default }}", environment];
          return event;
        },
      });
    }

    // Add our console.error interceptor after Sentry so that we can prevent non-errors getting to sentry.
    const originalError = console["error"];
    console["error"] = (...args) => {
      if (args[0] === "React Router caught the following error during render" && args[1]?._isNominal) {
        // Suppress stupid react errors
        return;
      } else if (args[0]?._isNominal) {
        return;
      }
      originalError(...args);
    };
  } catch (ex) {
    // Don't interrupt whatever the caller was doing
    console.error(ex);
  }
}

export function analyticsIdentifyUser(user: RegisteredUser) {
  Sentry.setUser({id: user.user_id, email: user.primary_email, username: user.name});

  plausibleProps = {
    ...plausibleProps,
    isPlatformedUser: user.primary_email.endsWith("@platformed.com"),
    email: user.primary_email,
  };
}

export function analyticsIdentifyAccount(account: Account) {
  Sentry.setTags({
    "account.id": account.account_id,
    "account.name": account.display_name,
    "account.type": account.type_,
  });

  plausibleProps = {
    ...plausibleProps,
    accountName: account.name,
  };
}
