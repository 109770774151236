import {ExternalAuthorizationProvider, FrontendEnvironment, OAuthRedirectTarget, PublicCredentials} from "../Types";
import {unreachableCase} from "./typescript";

export function getFrontendEnvironment(): FrontendEnvironment {
  return (window.document as any).platformed_config;
}

export function getPublicCredentials(): PublicCredentials {
  return getFrontendEnvironment().credentials;
}

export function getOAuthRedirectUrl(target: OAuthRedirectTarget): string {
  const redirectUrl = new URL(getFrontendEnvironment().app_url);
  redirectUrl.pathname = "/redirect/" + target;

  if (redirectUrl.protocol === "http:") {
    // In some cases we can support OAuth over HTTP by redirecting via
    // localhost, which is special-cased by some servers.
    redirectUrl.hostname = "localhost";
  }
  return redirectUrl.toString();
}

export function oAuthRedirectTargetFromProvider(provider: ExternalAuthorizationProvider): OAuthRedirectTarget {
  switch (provider) {
    case ExternalAuthorizationProvider.Google:
      return OAuthRedirectTarget.Google;
    case ExternalAuthorizationProvider.Atlassian:
      return OAuthRedirectTarget.Atlassian;
    case ExternalAuthorizationProvider.Hubspot:
      return OAuthRedirectTarget.Hubspot;
    case ExternalAuthorizationProvider.Slack:
      return OAuthRedirectTarget.Slack;
    case ExternalAuthorizationProvider.Microsoft:
      return OAuthRedirectTarget.MicrosoftWeb;
    case ExternalAuthorizationProvider.Docusign:
      return OAuthRedirectTarget.Docusign;
    case ExternalAuthorizationProvider.Notion:
      return OAuthRedirectTarget.Notion;
    case ExternalAuthorizationProvider.Manual:
      throw new Error("Not applicable");
    default:
      unreachableCase(provider);
  }
}
