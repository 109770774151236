import {Dispatch, SetStateAction, useCallback} from "react";
import {AccountId} from "../../Types";
import {readClientAccountLocalState, useClientAccountLocalState} from "./clientAccountLocalState";
import {q, resetQueries} from "../../state";
import {Type} from "../../utils/typing";

const trustCenterPasswordStorageKey = "trust-center-password";

export function useTrustCenterPassword(): [string, Dispatch<SetStateAction<string>>] {
  const [password, setPasswordInner] = useClientAccountLocalState(trustCenterPasswordStorageKey, Type.String(), "");
  const setPassword = useCallback(
    (action: SetStateAction<string>) => {
      setPasswordInner(action);
      resetQueries([q.external.filter()]);
    },
    [setPasswordInner],
  );
  const url = new URL(window.location.href);
  if (url.searchParams.has("password")) {
    const newPassword = url.searchParams.get("password") ?? "";
    url.searchParams.delete("password");
    window.history.replaceState(null, "", url.toString());
    setPassword(newPassword);
  }
  return [password, setPassword] as const;
}

export function getTrustCenterPassword(accountId: AccountId): string {
  return readClientAccountLocalState(accountId, trustCenterPasswordStorageKey, Type.String(), "");
}
