import {GoogleOAuthProvider} from "@react-oauth/google";
import {MsalProvider} from "./ms";
import {getPublicCredentials} from "../../utils/environment";

const {google_client_id, ms_client_id} = getPublicCredentials();

const OauthProviders = ({children}: {children?: React.ReactNode}) => {
  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <MsalProvider clientId={ms_client_id}>{children}</MsalProvider>
    </GoogleOAuthProvider>
  );
};

export default OauthProviders;
