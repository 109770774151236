import {q, useQueryData} from "../../state";
import {getFrontendEnvironment} from "../../utils/environment";
import {Type} from "../../utils/typing";
import {useTypedParams} from "../../hooks/typedParams";

const {custom_domain} = getFrontendEnvironment();

export const ClientAccountSlugParams = Type.Object({clientAccountSlug: Type.String()});

export const useClientAccount = custom_domain
  ? () => {
      return useQueryData(q.external.account(custom_domain.account_id));
    }
  : () => {
      const {clientAccountSlug} = useTypedParams(ClientAccountSlugParams);
      return useQueryData(q.external.accountBySlug(clientAccountSlug));
    };
