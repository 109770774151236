import {HTTPError} from ".";
import {CreateStandaloneFile, DownloadOptions, File as FileT, FileId, StandaloneFile, UploadOptions} from "../Types";
import jsonApi, {addAccountId} from "./jsonApi";

export class NetworkError extends Error {}

export async function uploadImpl(
  url: string,
  file: File,
  progressFn?: (progress: number) => void,
  signal?: AbortSignal,
): Promise<FileT> {
  // Make sure the signal isn't already aborted
  signal?.throwIfAborted();
  const xhr = new XMLHttpRequest();
  const abort = () => {
    xhr.abort();
  };
  signal?.addEventListener("abort", abort);

  const formData = new FormData();
  formData.append("file", file);

  if (progressFn) {
    xhr.upload.onprogress = ev => {
      progressFn(ev.loaded / ev.total);
    };
  }

  try {
    return await new Promise((resolve, reject) => {
      xhr.onreadystatechange = () => {
        switch (xhr.readyState) {
          // Cancelled
          case 0:
            reject(signal?.reason);
            break;
          // Completed
          case 4:
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else if (xhr.status !== 0) {
              reject(
                new HTTPError(
                  new Response(JSON.stringify(xhr.response), {status: xhr.status, statusText: xhr.statusText}),
                ),
              );
            } else {
              reject(new NetworkError("Unknown network error"));
            }
            break;
          default:
            break;
        }
      };

      xhr.responseType = "json";
      xhr.withCredentials = true;
      xhr.open("POST", url, true);
      xhr.send(formData);
    });
  } finally {
    signal?.removeEventListener("abort", abort);
  }
}

export async function upload(
  file: File,
  options: UploadOptions = {},
  progressFn?: (progress: number) => void,
  signal?: AbortSignal,
): Promise<FileT> {
  const url = addAccountId(`/api/files?${jsonApi.encodeQuery(options)}`);
  return uploadImpl(url, file, progressFn, signal);
}

export const getPublicUrl = async (fileId: FileId) => {
  return await jsonApi.get<string>(`/files/${fileId}/public_url`);
};

export const download = async (fileId: FileId, options: DownloadOptions = {}) => {
  const a = document.createElement("a");
  a.href = addAccountId(`/api/files/${fileId}/download?${jsonApi.encodeQuery(options)}`);
  a.click();
};

export const createStandalone = async (standaloneFile: CreateStandaloneFile) => {
  return await jsonApi.post<StandaloneFile>(`/standalone_files`, standaloneFile);
};
