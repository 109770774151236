import {invalidateQueries, q} from "../../state";
import {CreateTprmAsset, TprmAsset, TprmAssetId, UpdateTprmAsset} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(): Promise<TprmAsset[]> {
  return await jsonApi.get<TprmAsset[]>("/tprm/assets");
}

export async function create(asset: CreateTprmAsset) {
  const res = await jsonApi.post<"ok">("/tprm/assets", asset);
  await invalidateQueries([q.tprm.assets.filter()]);
  return res;
}

export async function update(assetId: TprmAssetId, update: UpdateTprmAsset) {
  const res = await jsonApi.patch<"ok">(`/tprm/assets/${assetId}`, update);
  await invalidateQueries([q.tprm.assets.filter()]);
  return res;
}

export async function delete_(assetId: TprmAssetId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/assets/${assetId}`);
  await invalidateQueries([q.tprm.assets.filter()]);
  return res;
}
