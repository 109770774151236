import {Button, HStack, ModalBody, ModalCloseButton, ModalFooter, ModalHeader} from "@chakra-ui/react";
import {usePromiseState} from "../../hooks/promiseState";
import {withModal} from "../../state/withModal";

export type ConfirmationModalProps = {
  onConfirm: () => Promise<unknown>;

  modalHeader: React.ReactNode;
  modalBody: React.ReactNode;
  buttonLabel: React.ReactNode;
  colorScheme?: string;
};

const ConfirmationModal = withModal(
  ({
    onClose,
    onConfirm,
    modalHeader,
    modalBody,
    buttonLabel,
    colorScheme = "red",
  }: ConfirmationModalProps & {onClose: () => void}) => {
    const [confirming, confirm] = usePromiseState(async () => {
      await onConfirm();
      onClose();
    }, [onConfirm, onClose]);
    return (
      <>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="md">{modalBody}</ModalBody>
        <ModalFooter>
          <HStack spacing="3">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={colorScheme}
              isDisabled={confirming.inProgress}
              onClick={confirm}
              isLoading={confirming.inProgress}
            >
              {buttonLabel}
            </Button>
          </HStack>
        </ModalFooter>
      </>
    );
  },
);

export default ConfirmationModal;
