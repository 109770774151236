import {ReactNode} from "react";
import {confirmationModal} from ".";
import {provideModal} from "../../components/modals";
import {ModalProvider} from "../../components/modals/ModalProvider";
import ConfirmationModal from "./ConfirmationModal";

const AppModalProvider = ({children}: {children: ReactNode}) => {
  return (
    <ModalProvider
      modals={[
        provideModal(confirmationModal, modalProps => {
          return <ConfirmationModal {...modalProps} />;
        }),
      ]}
    >
      {children}
    </ModalProvider>
  );
};

export default AppModalProvider;
