import {
  ApiDateTime,
  CertificationType,
  CreateDocument,
  Document,
  DocumentCategory,
  DocumentId,
  DocumentMin,
  EsignatureTemplateId,
  OwnerId,
  ReviewPeriod,
  ScopeId,
  SharingClassification,
  SourcedFact,
  TrustCenterAccess,
  UpdateDocumentContent,
} from "../../Types";
import {invalidateQueries, q} from "../../state"; // Updated import to include q
import jsonApi from "../jsonApi";

export async function list(): Promise<DocumentMin[]> {
  return await jsonApi.get<DocumentMin[]>("/vendor_toolkit/documents");
}

export async function get(documentId: DocumentId): Promise<Document> {
  return await jsonApi.get<Document>(`/vendor_toolkit/documents/${documentId}`);
}

export async function getFacts(documentId: DocumentId): Promise<SourcedFact[]> {
  return await jsonApi.get<SourcedFact[]>(`/vendor_toolkit/documents/${documentId}/facts`);
}

export async function create(document: CreateDocument): Promise<Document> {
  const res = await jsonApi.post<Document>("/vendor_toolkit/documents", document);
  await invalidateQueries([q.vendorToolkit.documents.filter(), q.vendorToolkit.gettingStartedChecklist.filter()]);
  return res;
}

export async function updateNonAuthoritativeMarkdown(documentId: DocumentId, markdown: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/non_authoritative_markdown`, markdown);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateReviewPeriod(documentId: DocumentId, reviewPeriod: ReviewPeriod) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/review_period`, reviewPeriod);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateName(documentId: DocumentId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/name`, name);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateSharingClassification(
  documentId: DocumentId,
  sharing_classification: SharingClassification,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/sharing_classification`,
    sharing_classification,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateCategory(documentId: DocumentId, category: DocumentCategory) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/category`, category);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function assign(documentId: DocumentId, ownerId: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/assign`, ownerId);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateContent(documentId: DocumentId, content: UpdateDocumentContent) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}`, content);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}

export async function updateTrustCenterVisibility(documentId: DocumentId, visibility: TrustCenterAccess) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/trust_center_visibility`, visibility);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateTrustCenterReadability(documentId: DocumentId, readability: TrustCenterAccess) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/trust_center_readability`, readability);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateTrustCenterEsignatureTemplate(
  documentId: DocumentId,
  templateId: EsignatureTemplateId | null,
) {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/trust_center_esignature_template`,
    templateId,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateDocumentCertificationType(
  documentId: DocumentId,
  certificationType: CertificationType | null,
): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/certification_type`, certificationType);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateDocumentCertificationDateGranted(
  documentId: DocumentId,
  certificationDateGranted: ApiDateTime | null,
): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/certification_date_granted`,
    certificationDateGranted,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateDocumentCertificationDateExpires(
  documentId: DocumentId,
  certificationDateExpires: ApiDateTime | null,
): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/certification_date_expires`,
    certificationDateExpires,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateDocumentCertificationAuditorUrl(
  documentId: DocumentId,
  certificationAuditorUrl: string | null,
): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/certification_auditor_url`,
    certificationAuditorUrl,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateDocumentCertificationAuditReport(
  documentId: DocumentId,
  certificationAuditReport: DocumentId | null,
): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(
    `/vendor_toolkit/documents/${documentId}/certification_audit_report`,
    certificationAuditReport,
  );
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function deleteDocument(documentId: DocumentId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/documents/${documentId}`);
  await invalidateQueries([
    q.vendorToolkit.documents.filter(),
    q.vendorToolkit.document.filter(documentId),
    q.vendorToolkit.documentSources.filter(),
    q.vendorToolkit.facts.filter(),
    q.vendorToolkit.documentFacts.filter(),
  ]);
  return res;
}

export async function updateLimitedToScopes(documentId: DocumentId, scopeIds: ScopeId[] | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/limited_to_scopes`, scopeIds);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function updateFactGenPrompt(documentId: DocumentId, factGenPrompt: string): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/prompt`, factGenPrompt);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function resetPrompt(documentId: DocumentId): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/prompt/reset`);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId)]);
  return res;
}

export async function reanalyze(documentId: DocumentId): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/documents/${documentId}/reanalyze`);
  await invalidateQueries([q.vendorToolkit.document.filter(documentId), q.vendorToolkit.documents.filter()]);
  return res;
}
