import {createContext} from "react";
import {RpcClient} from "platformed-browser-api/rpcClient";
import {
  CHANNEL_KEY,
  CURRENT_VERSION,
  Fingerprint,
  PlatformedApi,
  PlatformedEvent,
} from "platformed-browser-api/platformed";
import {WindowMessageChannel} from "platformed-browser-api/channel";
import {invalidateQueries, q} from "../state";
import {unreachableCase} from "../utils/typescript";

type ExtensionState = {
  fingerprint: Fingerprint | null;
};

export class ExtensionClient extends RpcClient<PlatformedApi> {
  #subscribers: (() => void)[] = [];
  #state: ExtensionState = {fingerprint: null};

  get state() {
    return this.#state;
  }

  constructor() {
    super(WindowMessageChannel.between(window.parent, window, CHANNEL_KEY), CURRENT_VERSION);
  }

  onHello(_supportedVersion: boolean): void {}

  onEvent(event: PlatformedEvent): void {
    switch (event.type) {
      case "Fingerprinted":
        this.#updateState({...this.state, fingerprint: event.fingerprint});
        break;
      case "LoginStateChanged":
        invalidateQueries([q.whoAmI.filter(), q.unauthenticated.filter()]);
        break;
      default:
        unreachableCase(event);
    }
  }

  #updateState(newState: ExtensionState) {
    this.#state = newState;
    for (const subscriber of this.#subscribers) {
      subscriber();
    }
  }

  subscribe(cb: () => void): () => void {
    this.#subscribers.push(cb);
    return () => {
      const idx = this.#subscribers.lastIndexOf(cb);
      if (idx !== -1) {
        this.#subscribers.splice(idx, 1);
      }
    };
  }
}

setInterval(() => window.parent.postMessage("Test"), 1000);

export const extensionClient = new ExtensionClient();
export const extensionContext = createContext(extensionClient.state);
