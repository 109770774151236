import type {IPublicClientApplication} from "@azure/msal-browser";
import React, {useCallback, useEffect, useState} from "react";
import {getOAuthRedirectUrl} from "../../utils/environment";
import {OAuthRedirectTarget} from "../../Types";
import {handleImportError} from "../../utils/import";

export const MsalContext = React.createContext<IPublicClientApplication | null>(null);

export const MsalProvider = ({children, clientId}: {children?: React.ReactNode; clientId: string}) => {
  const [value, setValue] = useState<IPublicClientApplication | null>(null);
  const redirectUri = getOAuthRedirectUrl(OAuthRedirectTarget.MicrosoftSpa);
  const initializeApp = useCallback(async () => {
    const {PublicClientApplication} = await import("@azure/msal-browser").catch(handleImportError);
    const msalInstance = await PublicClientApplication.createPublicClientApplication({
      auth: {
        authority: "https://login.microsoftonline.com/common",
        clientId,
        redirectUri,
      },
    });
    setValue(msalInstance);
  }, [clientId, redirectUri]);
  useEffect(() => {
    setValue(null);
    initializeApp();
  }, [initializeApp]);
  return <MsalContext.Provider value={value}>{children}</MsalContext.Provider>;
};
