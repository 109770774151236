/* eslint-disable @typescript-eslint/no-empty-object-type */
"use client";

import type {IconButtonProps} from "@chakra-ui3/react";
import {ClientOnly, IconButton, Skeleton} from "@chakra-ui3/react";
import {ThemeProvider} from "next-themes";
import type {ThemeProviderProps} from "next-themes";
import * as React from "react";
import {LuMoon, LuSun} from "react-icons/lu";
import {useColorMode} from "./color-mode/hooks";

export interface ColorModeProviderProps extends ThemeProviderProps {}

export function ColorModeProvider(props: ColorModeProviderProps) {
  return <ThemeProvider attribute="class" disableTransitionOnChange {...props} />;
}

export function ColorModeIcon() {
  const {colorMode} = useColorMode();
  return colorMode === "dark" ? <LuMoon /> : <LuSun />;
}

interface ColorModeButtonProps extends Omit<IconButtonProps, "aria-label"> {}

export const ColorModeButton = React.forwardRef<HTMLButtonElement, ColorModeButtonProps>(
  function ColorModeButton(props, ref) {
    const {toggleColorMode} = useColorMode();
    return (
      <ClientOnly fallback={<Skeleton boxSize="8" />}>
        <IconButton
          onClick={toggleColorMode}
          variant="ghost"
          aria-label="Toggle color mode"
          size="sm"
          ref={ref}
          {...props}
          css={{
            _icon: {
              width: "5",
              height: "5",
            },
          }}
        >
          <ColorModeIcon />
        </IconButton>
      </ClientOnly>
    );
  },
);
