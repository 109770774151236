import jsonApi, {DEFAULT_API_OPTIONS} from "./jsonApi";
import {
  AccountWithRestriction,
  LoginRequest,
  WhoAmI,
  GenerateTotpResponse,
  UpdateTwoFa,
  RegisteredUser,
  RequestRegistration,
  PreLoginResponse,
  ProductType,
} from "../Types";
import {analyticsIdentifyAccount, analyticsIdentifyUser} from "../utils/analytics";
import {invalidateQueries, q, resetQueries} from "../state";
import {router} from "../router";
import {clearSessionState} from "../hooks/sessionState";

export async function whoAmI(options = DEFAULT_API_OPTIONS) {
  const res = await jsonApi.post<WhoAmI>("/whoami", null, options);
  if (res.user) {
    analyticsIdentifyUser(res.user);
  }
  if (res.account) {
    analyticsIdentifyAccount(res.account);
  }
  return res;
}

export async function logout() {
  await jsonApi.post<string>("/logout");
  clearSessionState();
  await resetQueries();
  await router!.navigate("/login");
}

export async function preLogin(email: string) {
  const res = await jsonApi.post<PreLoginResponse>("/pre_login", email);
  return res;
}

export async function login(body: LoginRequest) {
  const res = await jsonApi.post<RegisteredUser>("/login", body);
  await resetQueries();
  return res;
}

export async function register(body: RequestRegistration) {
  return await jsonApi.post<"ok">("/register", body);
}

export async function changeName(name: string) {
  const res = await jsonApi.post<"ok">("/login/name", name);
  await invalidateQueries([q.whoAmI.filter(), q.user.filter(), q.registeredUsers.filter()]);
  return res;
}

export async function listAccounts() {
  return await jsonApi.get<AccountWithRestriction[]>("/login/accounts");
}

export async function generateTotp() {
  return await jsonApi.get<GenerateTotpResponse>("/login/2fa/totp");
}

export async function update2FA(update: UpdateTwoFa) {
  const res = await jsonApi.post<"ok">("/login/2fa", update);
  await invalidateQueries([q.whoAmI.filter(), q.user.filter(), q.registeredUsers.filter()]);
  return res;
}

export async function updateInternalMode(internalMode: boolean) {
  const res = await jsonApi.post<"ok">("/login/internal_mode", internalMode);
  await resetQueries();
  return res;
}

export async function logSession({product}: {product: ProductType}) {
  const res = await jsonApi.post<"ok">("/session", {product});
  return res;
}
