import api from "../../../api";
import {OperationalContextId} from "../../../Types";
import {param, typedQuery} from "../../typing";

export const operationalContext = typedQuery(
  ["tprm", "operationalContext", param<OperationalContextId>("tprmOperationalContextId")],
  async operationalContextId => {
    return await api.tprm.operationalContexts.get(operationalContextId);
  },
);

export const operationalContextAssets = typedQuery(
  ["tprm", "operationalContextAssets", param<OperationalContextId>("tprmOperationalContextId")],
  async operationalContextId => {
    return await api.tprm.operationalContexts.listAssets(operationalContextId);
  },
);
