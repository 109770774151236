import {createSystem, defaultConfig} from "@chakra-ui3/react";
import {checkboxAnatomy, tableAnatomy, tagAnatomy} from "@chakra-ui3/react/anatomy";

export const system = createSystem(defaultConfig, {
  cssVarsPrefix: "chakra3",
  // Needed, otherwise chakra2's unlayered styles will override chakra3's layered styles
  disableLayers: true,
  globalCss: {
    "table.spacious td, table.spacious td>a.cell-link": {
      py: 5,
      px: 4,
    },
    "table.spacious th": {
      py: 4,
      px: 4,
    },
    "table.spacious td:first-of-type, table.spacious th:first-of-type, table.spacious td:first-of-type>a.cell-link": {
      pl: 8,
    },
    "table.spacious td:last-of-type, table.spacious th:last-of-type, table.spacious td:last-of-type>a.cell-link": {
      pr: 8,
    },
  },
  theme: {
    tokens: {
      fonts: {
        heading: {
          value: `'Inter var', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        },
        body: {
          value: `'Inter var', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        },
      },
      zIndex: {
        hide: {value: -1},
        base: {value: 0},
        docked: {value: 10},
        // Default theme puts "dropdown" too low
        // Presumably Chakra copied this from Bootstrap, which made the same mistake...
        // https://github.com/twbs/bootstrap/issues/31747
        sticky: {value: 1000},
        banner: {value: 1100},
        dropdown: {value: 1200},
        overlay: {value: 1300},
        popover: {value: 1400},
        modal: {value: 1500},
        skipNav: {value: 1600},
        toast: {value: 1700},
        tooltip: {value: 1800},
        max: {value: 2147483647},
      },
      colors: {
        gray: {
          50: {value: "#F7FAFC"},
          100: {value: "#EDF2F7"},
          200: {value: "#E2E8F0"},
          300: {value: "#CBD5E0"},
          400: {value: "#A0AEC0"},
          500: {value: "#718096"},
          600: {value: "#4A5568"},
          700: {value: "#2D3748"},
          800: {value: "#1A202C"},
          900: {value: "#171923"},
        },
      },
    },
    recipes: {
      icon: {
        base: {
          boxSize: "1em",
        },
      },
      button: {
        base: {
          // Remove these when Chakra V2 is gone
          fontWeight: "semibold",
          rounded: "md",
        },
      },
    },
    slotRecipes: {
      table: {
        slots: tableAnatomy.keys(),
        base: {
          row: {
            height: "full",
          },
        },
        variants: {
          interactive: {
            true: {
              body: {
                "& tr": {
                  _hover: {
                    bg: "colorPalette.subtle/25",
                  },
                },
              },
            },
          },
          size: {
            sm: {
              cell: {
                "&>a.cell-link": {
                  px: "2",
                  py: "2",
                },
              },
            },

            md: {
              cell: {
                "&>a.cell-link": {
                  px: "3",
                  py: "3",
                },
              },
            },

            lg: {
              cell: {
                "&>a.cell-link": {
                  px: "4",
                  py: "3",
                },
              },
            },
          },
        },
      },
      tag: {
        slots: tagAnatomy.keys(),
        variants: {
          variant: {
            surface: {
              root: {
                // Remove these when Chakra V2 is gone
                shadow: "none",
                fontWeight: "500",
              },
            },
          },
        },
      },
      checkbox: {
        slots: checkboxAnatomy.keys(),
        base: {
          control: {
            bg: "white",
          },
        },
      },
    },
  },
});
